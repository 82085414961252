// WaGen___HASHKEY__fd8068dc_2022-05-06 15:03:15 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
工作单信息
*/
/* ---
标准CRUD方法：添加记录
*/
export function gzdGzdxx_add(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：qdrq : Dateywbh : Long
*/
export function gzdGzdxx_update(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：qdrq : Dateywbh : Long
*/
export function gzdGzdxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function gzdGzdxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function gzdGzdxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function gzdGzdxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：qdrq : Dateywbh : Long
*/
export function gzdGzdxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function gzdGzdxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function gzdGzdxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //GzdGzdxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function gzdGzdxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function gzdGzdxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //GzdGzdxx
		}
	})
}

/* ---
未提供注释
*/
export function gzdGzdxx_lockYkGzd(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/lockYkGzd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			}
		}
	})
}

/* ---
获取互联网用户业扩工单 参数：jgbm(必填),dlzh(必填),yyqd(必填),yhbh(选填 null_全部),gdzt(选填：0_已受理 1_已完成 2_已取消 null_全部),ywbh(选填 null_全部)
*/
export function gzdGzdxx_getHlwyhYkgdList(jgbm,dlzh,yyqd,gdzt,yhbh,ywbh, pagination, meta) {
	return fetch({
	 url : 'psdmsykgl/dawhschema/GzdGzdxx/getHlwyhYkgdList',
	 method : 'post',
	 headers : {
	 },
	 data : {
		param : {
		 jgbm : jgbm, //String
		 dlzh : dlzh, //String
		 yyqd : yyqd, //String
		 gdzt : gdzt, //String
		 yhbh : yhbh, //String
		 ywbh : ywbh //Long
		},
		pagination : pagination
	 }
	})
 }

/* ---
多记录插入
*/
export function gzdGzdxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<GzdGzdxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：qdrq : Dateywbh : Long
*/
export function gzdGzdxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<GzdGzdxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：qdrq : Dateywbh : Long
*/
export function gzdGzdxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/GzdGzdxx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<GzdGzdxx>
		}
	})
}

