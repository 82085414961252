<template>
  <page-view ref="pageView" :title="title" left-arrow>
    <template #nav-right>
      <yhbh-select v-model="form.bh" @confirm="yhbhChangeHandle" :custom-style="{}" />
    </template>
    
    <van-search v-model="form.bh" placeholder="请输入用户编号/业务编号" @search="handleSearch" style="padding-bottom: 4px;"/>

    <!-- 工单列表 -->
    <van-tabs v-model="activeTabIndex" sticky offset-top="44" title-active-color="#00b1ff" color="#00b1ff" @click="handleTabsClick">
      <van-tab title="全部" name="all"></van-tab>
      <van-tab title="已受理" name="0"></van-tab>
      <van-tab title="已完成" name="1"></van-tab>
      <van-tab title="已取消" name="2"></van-tab>
      
      <loading-layout ref="listLoadingLayout" error-text="工单查询失败" empty-text="暂无工单" @reaty="fetchData(true)">
        <van-list v-model="listLoading" 
          style="margin-top: 10px;"
          :finished="listFinished" 
          finished-text="没有更多了" 
          :error.sync="listLoadError" 
          error-text="加载失败，点击重试" 
          @load="fetchData">
            <van-cell
            v-for="(item, idx) in list"
            :key="idx"
            class="gdxx-item"
            :title="item.ywlbmc"
            is-link
            @click="handleGzdClick(item)">
            {{item.gdzt | dqztFilter}}
            <template slot="label">
              <p>业务编号：{{item.ywbh}}</p>
              <p>受理时间：{{item.cjsj}}</p>
              <p>处理状态：{{item.gnmc}}</p>
            </template>
          </van-cell>
        </van-list>
      </loading-layout>
    </van-tabs>
  </page-view>
</template>

<script>
  import { mapGetters } from 'vuex';

  import {
    Tab,
    Tabs,
    Search
  } from 'vant';
  import Loading from '@/components/LoadingLayout/loading.vue';
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import YhbhSelect from '@/components/YhbhSelect/index.vue';

  import { gzdGzdxx_getHlwyhYkgdList } from '@/api/psdmsykgl/dawhschema/GzdGzdxxAPI';

  const PAGE_SIZE = 10;
  export default {
    name: 'gdcx',
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Search.name]: Search,
      Loading,
      YhbhEmpty,
      YhbhSelect,
    },
    data() {
      return {
        title: '业务进度查询',
        gnid: '1027', // 业扩变更受理
        gzdxx: {
          qdrq: '',
          ywbh: ''
        },
        form: {
          bh: '',
        },
        activeTabName: 'all',
        activeTabIndex: 0,
        list: [1,2,3,4,5,6,7,9,9,4,2,3,4],
        listLoading: false,
        listFinished: false,
        listLoadError: false,

        pagination: {
          pageNum: 1,
          pageSize: PAGE_SIZE,
          total: 0
        }
      }
    },
    filters: {
      dqztFilter(val) {
        let str;
        switch (val+'') {
          case '0':
            str = '受理中';
            break;
          case '1':
            str = '已完成';
            break;
          case '2':
            str = '已取消';
            break;
        
          default:
            break;
        }
        return str;
      }
    },
    computed: {
      ...mapGetters(['jgbm','dybm','czyh','dlzh']),
    },
    methods: {
      yhbhChangeHandle(yhxx) {
        this.form.bh = yhxx.yhbh;

        this.fetchData(true);
      },

      handleSearch() {
        this.activeTabIndex = 0;
        this.activeTabName = 'all';
        this.fetchData(true);
      },

      fetchData(isRefresh = false) {
        if (isRefresh) {
          this.pagination = {
            pageNum: 1,
            pageSize: PAGE_SIZE,
            total: 0
          }
          this.$refs['listLoadingLayout'].setStatus("loading");
          this.listFinished = false;
          this.list = [];
        }
        let pagination = {
          pageNum: this.pagination.pageNum,
          pageSize: this.pagination.pageSize
        }
        this.listLoading = true;

        let gdzt = this.activeTabName; // 0_已受理 1_已完成 2_已取消 null_全部
        if (this.activeTabName == 'all') {
          gdzt = null;
        }
        let yhbh = null,
            ywbh = null;
        if (this.form.bh.length == 10) {
          yhbh = this.form.bh;
        } else {
          ywbh = this.form.bh;
        }
        // jgbm,dlzh,yyqd,gdzt,yhbh,ywbh
        gzdGzdxx_getHlwyhYkgdList(this.jgbm, this.dlzh, "1", gdzt, yhbh,ywbh, pagination).then(res => {
          this.listLoading = false;
          console.log(res);
          if (res.code == 200) {
            this.list = this.list.concat(res.content);
            this.pagination.total = res.pagination.total;
            if (isRefresh) {
              if (res.pagination.total == 0) {
                this.$refs['listLoadingLayout'].setStatus("empty");
              } else {
                this.$refs['listLoadingLayout'].setStatus("success");
              }
            }

            if (this.list.length >= res.pagination.total) {
              this.listFinished = true;
            } else {
              this.pagination.pageNum += 1;
            }
          } else {
            this.listLoadError = true;
          }
        }).catch(err => {
          console.log(err);
          this.listLoading = false;
          this.listLoadError = true;
          if (isRefresh) {
            this.$refs['listLoadingLayout'].setStatus("error");
          }
        });
      },
      handleTabsClick(activeTabName, activeTabTitle) {
        console.log(activeTabName, activeTabTitle)
        this.activeTabName = activeTabName;
        
        this.fetchData(true);
      },
      handleGzdClick(gzdxx) {
        this.$router.push({
          name: 'gdxx', 
          query: gzdxx
        });
      }
    },
    mounted() {
      this.$store.dispatch('getYhbhList').then(list => {
        if (list.length) {
          this.form.bh = list[0].yhbh;
          this.fetchData(true);
        }
      });
    },
  }
</script>

<style lang="scss" scoped>
  .gdxx-item /deep/ {
    align-items: center;
    .van-cell__title {
      flex: 2;
    }
  }
</style>